.ViewLayout {
  height: 100%;
  padding: 24px 24px 0;
  overflow: scroll;

  .Content {
    display: flex;
    flex-direction: column;
  }

  .MainSpace {
    flex: auto;
    width: 100%;

    display: flex;
    flex-direction: column;

    & > :last-child {
      flex: auto;
      padding-bottom: 24px;
    }
  }
}

@primary-color: #1871ed;@error-color: #D93616;@success-color: #63BE09;@warning-color: #FFBA00;@table-header-bg: transparent;@card-radius: 8px;@card-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(0, 0, 0, 0.1);@layout-header-background: #FFFFFF;@layout-body-background: #F2F2F2;@page-header-heading-title: 24px;@page-header-padding-vertical: 24px;@page-header-border-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);@btn-border-radius-base: 6px;