@import '~antd/dist/antd.less';

.GeoLikeMenu {
  border-right: @border-width-base @border-style-base #ffffff;

  :global(.ant-menu-sub) {
    background: #ffffff;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    font-size: 16px;
  }

  :global(.ant-menu-item-selected) {
    font-weight: bold;
  }

  :global(.anticon) {
    font-size: 18px;
  }
}

@primary-color: #1871ed;@error-color: #D93616;@success-color: #63BE09;@warning-color: #FFBA00;@table-header-bg: transparent;@card-radius: 8px;@card-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(0, 0, 0, 0.1);@layout-header-background: #FFFFFF;@layout-body-background: #F2F2F2;@page-header-heading-title: 24px;@page-header-padding-vertical: 24px;@page-header-border-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);@btn-border-radius-base: 6px;