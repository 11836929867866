@import '~antd/dist/antd.less';

.login_card {
  width: 100%;
  max-width: 800px;
  border: @border-width-base @border-style-base #dadde1;

  & .login_card_row {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;

    & .login_card_column {
      flex: auto;
      max-width: 500px;
      text-align: center;
    }
  }
}

@primary-color: #1871ed;@error-color: #D93616;@success-color: #63BE09;@warning-color: #FFBA00;@table-header-bg: transparent;@card-radius: 8px;@card-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(0, 0, 0, 0.1);@layout-header-background: #FFFFFF;@layout-body-background: #F2F2F2;@page-header-heading-title: 24px;@page-header-padding-vertical: 24px;@page-header-border-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);@btn-border-radius-base: 6px;